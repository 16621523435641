import React, { useState } from 'react';
import './App.css';
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, Avatar, Row, Input } from 'antd';
import { Home, About, People } from './pages';
import People3 from "./assets/people/3.jpg"
import logo from "./logo.png";
const { Header, Content, Footer, Sider } = Layout;
const { Search } = Input;

function getItem(label, key, children) {
  return {
    key,
    children,
    label,
  };
}
const paths = {
  "menu-home": "/",
  "menu-about": "/about",
  "menu-people": "/people",
};

const items = [
  getItem('Home', 'menu-home'),
  getItem('About', 'menu-about'),
  getItem('Services', 'services', [
    getItem('Admin', 'admin', [
      getItem('Global', 'admin-gl'),
      getItem('Australia', 'admin-au'),
      getItem('China', 'admin-cn'),
      getItem('India', 'admin-in'),
      getItem('East Asia', 'admin-ea'),
    ]),
    getItem('IT', 'it'),
    getItem('Finance', '5', [
      getItem('Global', 'finance-gl'),
      getItem('Australia', 'finance-au'),
      getItem('China', 'finance-cn'),
      getItem('India', 'finance-in'),
      getItem('East Asia', 'finance-ea'),
      getItem('UK', 'finance-uk'),
      getItem('USA', 'finance-us'),
    ]),
    getItem('HR', 'hr', [
      getItem('Global', 'hr-gl'),
      getItem('Australia', 'hr-au'),
      getItem('China', 'hr-cn'),
      getItem('India', 'hr-in'),
      getItem('East Asia', 'hr-ea'),
      getItem('UK', 'hr-uk'),
      getItem('USA', 'hr-us'),
    ]),
    getItem('Legal', 'legal'),
    getItem('Business Solutions', 'bs'),
    getItem('Marketing Solutions', 'ms'),
  ]),
  getItem('SOP', 'sop'),
  getItem('People', 'menu-people'),
  getItem('Careers', 'careers'),
];


function App() {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const onSearch = (value) => console.log(value);
  return (
    <div className="App">
      <Layout className="layout">
        <Header>
          <div className="logo">
            <img src={logo} />
          </div>
          <Row align='middle' style={{ flex: 1, justifyContent: "flex-end" }}>
            <Menu theme="light" defaultSelectedKeys={['1']} mode="horizontal" items={items}
              onClick={(e) => {
                if(paths[e.key]) {
                  navigate(paths[e.key]);
                }
              }} />
              <Search placeholder="Search.." allowClear onSearch={onSearch} style={{ width: 200 }} />
              <Avatar size={40} src={People3} style={{ marginLeft: 12 }} />
          </Row>
        </Header>
        <Content
          style={{
            padding: '16px 48px',
          }}
        >
          {/* <Breadcrumb
            style={{
              margin: '16px 0',
            }}
          >
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>List</Breadcrumb.Item>
            <Breadcrumb.Item>App</Breadcrumb.Item>
          </Breadcrumb> */}
          <div className="site-layout-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="people" element={<People />} />
            </Routes>
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
            color: "#aaa"
          }}
        >
          © 2018 George Clinical
        </Footer>
      </Layout>
    </div>
  );
}

export default App;

import React from "react";
import { Row, Col, List, Avatar, Carousel, Button } from "antd";
import {
  BellOutlined,
  FileTextOutlined,
  TeamOutlined,
  DatabaseOutlined,
  MailOutlined,
  CustomerServiceOutlined,
  DesktopOutlined,
  KeyOutlined,
  LineChartOutlined,
  CommentOutlined,
  GlobalOutlined,
  RiseOutlined,
  TrophyOutlined,
  MedicineBoxOutlined,
  ScheduleOutlined,
  SmileOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import Clock from 'react-live-clock';
import "./style.scss";
// const contentStyle = {
//   height: '480px',
//   color: '#fff',
//   lineHeight: '480px',
//   textAlign: 'center',
//   background: '#364d79',
// };
import Slide1 from "../../assets/slide1.jpg"
import Slide2 from "../../assets/slide2.jpg"
import Slide3 from "../../assets/slide3.jpg"

const slides = [
  {
    title: "Welcome to the 54th edition of SOPs, Training and Quality Newsletter",
    desc: "Q News is a global communications tool delivering important announcements on George Clinical policies, procedures, training and quality that impact George Clinical.",
    url: "https://gclinical.sharepoint.com/sites/GCWiz/Resources/Documents/202206_Q_News_54_Jul2022_final.pdf",
    image: Slide1,
    cta: "Read Now",
  },
  {
    title: "IBM Highlights George Clinical Data Management Successes",
    desc: "IBM recently highlighted the success George Clinical has enjoyed over the past several years leveraging the firm as a data, analytics and technology partner.",
    url: "https://www.georgeclinical.com/resources/corporate-news/ibm-highlights-management",
    image: Slide2,
  },
  {
    title: "George Clinical Provides Innovative Solutions to Mitigate Risks, Manage Fragile Biological Samples and Improve Patient Experience",
    desc: "Processes in multicenter study investigating safety and efficacy of combination drugs in patients with locally advanced or metastatic solid tumors amended successfully.",
    url: "https://www.georgeclinical.com/case-studies/innovative-solutions",
    image: Slide3,
    cta: "View Case Study",
  }
];

const flags = [
  {
    img: require("../../assets/flags/1.png"),
    tz: "Australia/Sydney",
  },
  {
    img: require("../../assets/flags/2.png"),
    tz: "Asia/Bangkok",
  },
  {
    img: require("../../assets/flags/3.png"),
    tz: "Asia/Kolkata",
  },
  {
    img: require("../../assets/flags/4.png"),
    tz: "GMT",
  },
  {
    img: require("../../assets/flags/5.png"),
    tz: "CET",
  },
  {
    img: require("../../assets/flags/6.png"),
    tz: "Asia/Singapore",
  },
  {
    img: require("../../assets/flags/7.png"),
    tz: "Asia/Bangkok",
  },
  {
    img: require("../../assets/flags/8.png"),
    tz: "Asia/Bangkok",
  },
  {
    img: require("../../assets/flags/9.png"),
    tz: "America/New_York",
  },
];

const links = [
  {
    url: "https://gclinical.sharepoint.com/sites/GCProject/Legal/CTRA",
    name: "CTRA Review",
    icon: <FileTextOutlined />,
    target: "_blank",
  },
  {
    url: "http://georgepeople.georgeinstitute.org/",
    name: "George People",
    icon: <TeamOutlined />,
    target: "_blank",
    title: "George People is not yet migrated. You are requested to right click and open this link in Incognito/Inprivtae mode and sign-in as mentioned below Sign-in : username@george.org.au Password: You use to login your laptop",
  },
  {
    url: "https://web.microsoftstream.com/group/43320385-d5dc-4375-b0ad-06c9d5d44e4c",
    name: "G​eorge TV​​​",
    icon: <DesktopOutlined />,
  },
  {
    url: "https://gclinical.sharepoint.com/sites/GCWiz/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FGCWiz%2FShared%20Documents%2FAccess%20Jedox%20%2D%20Migrated%20GC%20machine%2Epdf&amp;parent=%2Fsites%2FGCWiz%2FShared%20Documents",
    name: "Access Jedox - Migrated GC Machine",
    icon: <DatabaseOutlined />,
  },
  {
    url: "https://gclinical.sharepoint.com/sites/GCWiz/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FGCWiz%2FShared%20Documents%2FAccess%20Jedox%20%2D%20Non%2DMigrated%20GC%20machine%2Epdf&amp;parent=%2Fsites%2FGCWiz%2FShared%20Documents",
    name: "Access Jedox - Non-Migrated GC Machine",
    icon: <DatabaseOutlined />,
  },
  {
    url: "https://outlook.office.com/",
    name: "Webmail - GC",
    icon: <MailOutlined />,
    target: "_blank",
  },
  {
    url: "https://mail.tgi.cloud",
    name: "Webmail - TGI",
    icon: <MailOutlined />,
    target: "_blank",
  },
  {
    url: "https://it.georgeclinical.com/",
    name: "IT Ticketing System",
    icon: <CustomerServiceOutlined />,
    target: "_blank",
  },
  {
    url: "https://georgeclinicalservicedesk.freshservice.com/support/home",
    name: "Quality Ticketing System",
    icon: <CustomerServiceOutlined />,
    target: "_blank",
  },
  {
    url: "https://gcvault.georgeclinical.com",
    name: "Gclin Password Reset",
    icon: <KeyOutlined />,
    target: "_blank",
  },
  {
    url: "https://gc-force.my.salesforce.com/",
    name: "SalesForce",
    icon: <LineChartOutlined />,
    target: "_blank",
  },
  {
    url: "https://gclinical.sharepoint.com/sites/GCWiz/Marketing",
    name: "Marketing & Communications",
    icon: <CommentOutlined />,
    target: "_blank",
  },
  {
    url: "https://gclinical.sharepoint.com/sites/gcproject",
    name: "Sharepoint:Team sites",
    icon: <GlobalOutlined />,
    target: "_blank",
  },
  {
    url: "https://gclinical.sharepoint.com/sites/GCWiz/ps",
    name: "GC Business Solutions",
    icon: <RiseOutlined />,
    target: "_blank",
  },
  {
    url: "https://gclinical.sharepoint.com/sites/GCWiz/Resources/Pages/Trainings.aspx",
    name: "Training",
    icon: <TrophyOutlined />,
    target: "_blank",
  },
  {
    url: "https://gclinical.sharepoint.com/sites/gcproject/gcvendors",
    name: "GC Vendors",
    // icon: ,
    target: "_blank",
  },
  {
    url: "https://healthatworkdashboard.net.au/george-clinical",
    name: "Health@Work",
    icon: <MedicineBoxOutlined />,
    target: "_blank",
  },
  {
    url: "https://kstbookings.georgeinstitute.org/",
    name: "KST Bookings",
    icon: <ScheduleOutlined />,
    target: "_blank",
  },
  {
    url: "https://gclinical.sharepoint.com/sites/GCWiz/Lists/CB/Newform.aspx",
    name: "Compliment Box",
    icon: <SmileOutlined />,
    target: "_blank",
  },
];

const holidays = [
  {
    date: "15",
    month: "Aug",
    day: "Monday",
    name: "Independence Day",
  },
  {
    date: "31",
    month: "Aug",
    day: "Wednesday",
    name: "Ganesh Chathurthi",
  },
  {
    date: "08",
    month: "Sept",
    day: "Thursday",
    name: "Onam",
  },
  {
    date: "05",
    month: "Oct",
    day: "Wednesday",
    name: "Vijaya Dashami",
  },
];

const Home = () => {
  return (
    <div id="page-home">
      <Row gutter={24}>
        <Col span={16}>
          <Carousel autoplay autoplaySpeed={3000} dotPosition="bottom">
            { slides.map((s, sI) => (
              <div key={sI} className="slide-wrap">
                <div
                  style={{
                    height: 480,
                    backgroundImage: `url(${s.image})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                  }}
                >
                  {/* <img src={s.image} /> */}
                  <div className="slide-caption">
                    <h3>{s.title}</h3>
                    <p>{s.desc}</p>
                    <a href={s.url || "#"} target="_blank">{s.cta || "Read more"}</a>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
          <Row direction="horizontal" justify="center" align="middle" className="flags-wrap">
            { flags.map((f, fI) => (
              <div className="flag-wrap" key={fI}>
                <img src={f.img} />
                <Clock format={'HH:mm'} timezone={f.tz} />
              </div>
            ))
            }
          </Row>
          <div className="links-wrap">
            {links.map((l, lI) => (
              <Button href={l.url} target={l.target || "_self"} icon={l.icon || <LinkOutlined />}>{l.name}</Button>
            ))}
          </div>
        </Col>
        <Col span={8}>
          <div className="bg-white">
            <Row justify="space-between">
              <h3>Announcements</h3>
              <Button size="small">View All</Button>
            </Row>
            <List
              itemLayout="horizontal"
              dataSource={[0,0,0]}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar icon={<BellOutlined />} />}
                    title={<a href="https://ant.design">Important announcement</a>}
                    description="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint."
                  />
                </List.Item>
              )}
            />
          </div>
          <div className="bg-white">
            <Row justify="space-between">
              <h3>Upcoming Holidays</h3>
              <Button size="small">View All</Button>
            </Row>
            <List
              itemLayout="horizontal"
              dataSource={holidays}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <div className="holiday">
                        <h3>{item.date}</h3>
                        <p>{item.month}</p>
                      </div>
                    }
                    title={item.name}
                    description={item.day}
                  />
                </List.Item>
              )}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Home;